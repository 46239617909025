import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faStickyNote, faIdBadge, faUser, faCog } from '@fortawesome/free-solid-svg-icons'

interface HeaderProp {
    siteTitle: string;
}

const Header = ({ }: HeaderProp) => (
    <header>
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                {/* <a className="navbar-item" href="https://bulma.io">
          <img src="https://bulma.io/images/bulma-logo.png" width="112" height="28" />
        </a> */}

                <a role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div id="navbarBasicExample" className="navbar-menu">
                <div className="navbar-start">
                    <Link to="/" className="navbar-item">
                        {/* FontAwesome usage is here: https://fontawesome.com/how-to-use/on-the-web/using-with/react */}
                        <span className="icon">
                            <FontAwesomeIcon icon={faHome} className="fa-lg" />
                        </span>
                    </Link>

                    <Link to="/notes" className="navbar-item">
                        <FontAwesomeIcon icon={faStickyNote} title="Home" />
                        <span className="navbar-text">Notes</span>
                    </Link>
                    <Link to="/about" className="navbar-item">
                        <FontAwesomeIcon icon={faIdBadge} />
                        <span className="navbar-text">About</span>
                    </Link>

                    <div className="navbar-item has-dropdown is-hoverable">
                        <a className="navbar-link">
                            <FontAwesomeIcon icon={faCog} />
                            <span className="navbar-text">More</span>
                        </a>
                        <div className="navbar-dropdown">
                            <a className="navbar-item">About</a>
                            <Link to="/account" className="navbar-item">Account</Link>
                            {/* <hr className="navbar-divider" /> */}
                        </div>
                    </div>
                </div>
                <div className="navbar-end">
                    <div className="navbar-item">
                        <div className="buttons">
                            {/* <a className="button is-primary">
                                <strong>Sign up</strong>
                            </a> */}
                            <a className="button">
                                <span className="icon">
                                    <FontAwesomeIcon icon={faUser} />
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </header>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header